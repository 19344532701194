import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { gold, blue, black, mq } from "../styles/styles"

const Header = styled.header`
  position: relative;
`

const Title = styled.h1`
  margin: 0 auto;
  padding: 2rem;
  font-size: 1.2rem;
  @media (min-width: ${mq.medium}) {
    padding: 0;
    font-size: 1.8rem;
    width: 80%;
  }
  @media (min-width: ${mq.large}) {
    max-width: 50%;
    font-size: 2.25rem;
  }
  z-index: 20;
  display: grid;
  padding: 1rem 0;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  span {
    font-size: 1.8rem;
    line-height: 0.2rem;
  }
`

const Evan = styled.div`
  grid-column: 2 / span 2;
  align-self: end;
`
const Ampersand = styled.div`
  grid-row: 1 / span 2;
  text-align: right;
  font-size: 4rem;
  @media (min-width: ${mq.medium}) {
    font-size: 5.5rem;
  }
  transform: translateY(-10px);
  @media (min-width: ${mq.large}) {
    transform: translateY(-20px);
    font-size: 8.5rem;
  }
`
const Meaghan = styled.div`
  grid-column: 2 / span 2;
  align-self: start;
`

const HeaderComponent = ({ siteTitle }) => (
  <Header>
    <Link
      to="/"
      style={{
        color: `${black}`,
        display: "block",

        textDecoration: `none`,
      }}
    >
      <Title>
        <Evan>Evan Malone</Evan>
        <Ampersand>&amp;</Ampersand>
        <Meaghan>Meaghan Leon</Meaghan>
      </Title>
    </Link>
  </Header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default HeaderComponent
